[data-route^="sylius_shop_cart_summary"] .ui.coupon.action.input {
  @media (max-width: 991px) {
    width: 100%;
  }
}

[data-route^="sylius_shop_cart_summary"] .ui.coupon.action.input > * {
  @media (max-width: 991px) {
    width: 50%;
  }
}
