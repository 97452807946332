[data-route^="sylius_loyalty_shop_"] .breadcrumb,
[data-route^="sylius_return"] .breadcrumb {
    padding-bottom: 20px;
}

[data-route^="sylius_loyalty_shop_"] .ui.grid,
[data-route^="sylius_return"] .ui.grid {
    margin-top: 0;
}

[data-route^="sylius_loyalty_shop_"] .ui.container .ui.grid > .twelve.wide.column:last-child,
[data-route^="sylius_return"] .ui.container .ui.grid > .twelve.wide.column:last-child{
    padding-left: 60px;
}

[data-route^="sylius_loyalty_shop_"] .ui.container .ui.grid .column:last-child .ui.segment,
[data-route^="sylius_return"] .ui.container .ui.grid .column:last-child .ui.segment{
    border: none;
    box-shadow: none;
    padding: 0;
}

[data-route^="sylius_loyalty_shop_"] .ui.container .ui.grid .column:last-child .ui.top.attached.styled.header,
[data-route^="sylius_return"] .ui.container .ui.grid .column:last-child .ui.top.attached.styled.header{
    background: none;
    border: 0;
    padding: 0;
}

[data-route^="sylius_loyalty_shop_"] .ui.fluid.vertical.menu .item,
[data-route^="sylius_return"] .ui.fluid.vertical.menu .item {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #eee;
}

[data-route^="sylius_loyalty_shop_"] h1.header,
[data-route^="sylius_return"] h1.header {
    margin-top: 10px !important;
    margin-bottom: 20px;
    border: 0 !important;
}

[data-route^="sylius_loyalty_shop_"] h1.header .sub.header,
[data-route^="sylius_return"] h1.header .sub.header {
    padding-top: 15px;
}

@media (max-width: 768px) {
    .account-menu-item {
        margin-inline: 1.75rem;
    }
}
