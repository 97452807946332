.badge-circle-wrapper {
    position: relative;

    .badge-circle {
        width: 26px; height: 26px; overflow: hidden; position: absolute; border-radius: 13px;
        img {
            margin-left: -7px;
        }
    }
}

.badge-sidebar {
    left: -34px; top: -21px;
}

.badge-return-requests {
    top: -54px; left: -9px;
}
